<template>
			<div class="MainMenu">
				<button class="OpenButton" onClick="menu();"><img src="images/NavOpenIcon.png" srcset="images/NavOpenIconRetina.png 2x" alt="Menu" /></button>
				<nav class="Menu">
					<button class="CloseButton" onClick="menu();"><img src="images/NavCloseIcon.png" srcset="images/NavCloseIconRetina.png 2x" alt="Menu" /></button>
					<ul>
                        <!-- <li><router-link active-class="SelectedItem" :to="{ name: 'Home' }">Home</router-link></li> -->
                        <!-- <li><router-link active-class="SelectedItem" :to="{ name: 'Kit' }">Kit</router-link></li> -->
                        <!-- <li><router-link active-class="SelectedItem" :to="{ name: 'Abbigliamento', params: { subcat: 'ALL'} }">Abbigliamento</router-link></li> -->
					</ul>
				</nav>
			</div>
</template>

<script>

import MenuService from '@/services/menu.service.js';
import {risorseMixin} from "@/mixin.js";

export default {    
    name: "Menu",
    data(){
        return {
            linkPagine: []
        }
    },
    computed: {
    },
    mixins:[risorseMixin],  
    methods: {
        menu(e)
        {           
            e.stopPropagation();
            if ($('body').hasClass('menumobile')) {
                $('body').removeClass('menumobile')
            }
            else {
                $('body').addClass('menumobile')
            }
        },
        closeMenu(){
            $('body').removeClass('menumobile');
        }

    }, 
    mounted: function(){

    }
}
</script>